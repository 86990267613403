<script setup>
import { ref } from 'vue';
import { ModalDialog } from '@goodvibes/kudoslink';

defineExpose({ open, close });

const isOpen = ref(false);

let resolvePromise = null;

function open() {
    isOpen.value = true;
    return new Promise(resolve => {
        resolvePromise = resolve;
    });
}

function close() {
    isOpen.value = false;
}

function handleModalToggle(value) {
    value === false && resolvePromise({ confirmed: false });
    isOpen.value = value;
}

function handleConfirm() {
    resolvePromise && resolvePromise({ confirmed: true });
    close();
}

function handleCancel() {
    resolvePromise && resolvePromise({ confirmed: false });
    close();
}
</script>

<template>
    <ModalDialog :isOpen="isOpen" @update:isOpen="handleModalToggle">
        <template #icon>
            <slot name="icon"></slot>
        </template>
        <template #title>
            <slot name="title"></slot>
        </template>
        <slot></slot>
        <template #actions v-bind="{ handleConfirm, handleCancel }">
            <button @click="handleConfirm" class="btn btn--narrow text-[#ffff] bg-[#6212E5] hover:bg-[#7E39EF]">
                Confirm
            </button>
            <button @click="handleCancel" class="btn btn--tertiary btn--narrow btn--outline">Cancel</button>
        </template>
    </ModalDialog>
</template>
